
import { Component, Vue } from 'vue-property-decorator';
import { BugModel } from '@/models/bug';
import { component as VueCodeHighlight } from 'vue-code-highlight';

@Component({
  components: {
    VueCodeHighlight,
  },
})
export default class Bugs extends Vue {
  public isLoading: boolean = true;
  public updating: boolean = false;
  public bug: BugModel = new BugModel();

  public created() {
    this.isLoading = true;
    this.bug = new BugModel();
    this.bug.id = Number(this.$route.params.id);
    this.fetchData();
    this.isLoading = false;
  }

  public async fetchData() {
    this.isLoading = true;
    await this.bug.fetch();
    this.isLoading = false;
  }

  public async markAsFixed() {
    this.updating = true;
    await this.bug.update('CLOSED');
    this.updating = false;
  }

  public async markAsFixedAndCancel() {
    this.updating = true;
    await this.bug.update('CLOSED', true);
    this.updating = false;
  }
}
